import axios from 'axios';
import { AuthenticationService } from './authentication.service';
import Compressor from 'compressorjs';
  import * as UrlConsts from '@/scripts/UrlConsts';



export class ImageTransferService 
{
  constructor() {
    const a = 2; // interdit d'avoir un constructeur vide
  }

  /**
   * @desc transfère une image vers le serveur
   * @param imgUrl le data Url de l'image en base64
   * @param reqUrl url de requète serveur
   * @returns l'id de l'image stockée
   */
  public async transferImg(imgUrl : string, reqUrl : string, filename? :string, progressCallback? : any, cbParam? : any, cancelTokenSource? : any) : Promise<any> {
    const base64Img = this.getBase64Image(imgUrl);
    let blobData : Blob;
    try {
      blobData = this.base64ToBlobImg(base64Img);
    } catch(err) {
      console.error(err);
      throw err;
    }

    return new Promise(function(resolve, reject) {
      const headers = AuthenticationService.getRequestHeader();
      headers['Content-Type'] = 'multipart/form-data'; 
      if(progressCallback) {
        headers['onUploadProgress'] = (progressEvent : any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          progressCallback.call({}, percentCompleted, cbParam);
        }
      }
      if(cancelTokenSource) {
        headers['cancelToken'] = cancelTokenSource.token;
      }

      const file = new File([blobData], filename || "img");

      const formData = new FormData();
      formData.append('img', file, filename || "img");

      axios
        .post(reqUrl, formData, headers)
        .then((response) => {
          resolve(response.data);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        })
    })
  }

  /**
   * @desc transfert d'une image d'article au serveur
   * @param imgUrl l'image en base64
   * @param order l'odre le l'image
   * @param articleId l'id de l'article associé
   * @param progressCallback fonction à appeler pour transmettre le progrès de la transmission
   * @param cbParam paramètre à transmettre au progressCallback, en plus de l'avancement
   * @param cancelTokenSource token d'interruption du transfert d'une image
   * @returns l'id de l'image transmise en cas de succès, 0 sinon
   */
  public transferArticleImg(imgUrl: string, order: number, articleId : number, progressCallback : any, cbParam? : any, cancelTokenSource?: any) : Promise<any>{
    const filename = articleId + "_" + order;
    return this.transferImg(imgUrl,   UrlConsts.saveArticleImg, filename, progressCallback, cbParam, cancelTokenSource);
  }

  /**
   * @desc supprime l'en-tête dans le data Url 
   * @param imgUrl 
   * @returns 
   */
  private getBase64Image(imgUrl : string) : string {
    return imgUrl.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
  }

  /**
   * @desc converti l'image de base64 vers Blob
   * @param base64 
   * @returns 
   */
   private base64ToBlobImg(base64 : string) : Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);

    return blob;
  }



  /**
   * @desc redimensionne une image
   * @param blob l'image à redimensionner
   * @param maxWidth // largeur maximale
   * @param maxHeight // hauteur maximale
   * @param quality // qualité
   * @returns l'image redimensionnée en Blob ou File, ou une erreur
   */
  public resizeImg(blob : Blob | File, maxWidth : number, maxHeight : number, quality? : number) : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      new Compressor(blob, {
        quality: quality || 0.8,
        maxWidth,
        maxHeight,
  
        success(result) {
          resolve(result);
        },
        error(err) {
          console.log(err.message);
          reject(err.message);
        },
      });
    });
  }
}