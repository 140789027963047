
  import { ImageTransferService } from '@/services/image-transfer.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

  const imgWidth = process.env.VUE_APP_IMG_MAX_WIDTH;
  const imgMaxHeight = process.env.VUE_APP_IMG_MAX_HEIGHT;
  const largeImgMaxWidth = process.env.VUE_APP_LARGE_IMG_MAX_WIDTH;
  const largeImgMaxHeight = process.env.VUE_APP_LARGE_IMG_MAX_HEIGHT;

  /**
   * @desc boite de dialogue pour redimensionner une image selon un cadre indiqué
   */
  @Component({
    components: {
    },
  })
  export default class ImageCropper extends Vue {
    boxWidth = "50rem";
    imgSrc="";
    rotation=0;
    visible = false;
    returnParam : any;
    largeImage = false;

    imgTransferService = new ImageTransferService();

    // taille en % de la boite de redimension par rapport à la taille de l'image
    initWidth = 100;
    initHeight = 100;

    imgW = 0;
    imgH = 0;

    // ratio de l'image demandé
    @Prop()
    ratio : number | undefined;

    fixed = false;

    /**
     * @desc montre la boite de dialogue, avec l'image correspondant au src à modifier
     */
    public async show(src :string, fixed : boolean, largeImage = false, rotation? : number, returnParam? : any) : Promise<void> {
      this.imgSrc = src;
      this.visible = true;
      this.rotation = rotation || 0;
      this.fixed = fixed;
      this.returnParam = returnParam;
      this.largeImage = largeImage;

      if(!this.fixed)
        this.initResizer();
    }

    /**
     * @desc initialise le cadre de recadrement à la taille exacte de l'image,
     * si le clipper est basique
     */
    private initResizer() : void {
      let img = new Image();
      img.onload = () => {
        this.imgW = img.width;
        this.imgH = img.height;

        this.setInitSizes();
      };
      img.src = this.imgSrc;
    }

    /**
     * @desc initialise le cadre de recadrement à la taille exacte de l'image,
     * si le clipper est basique
     */
    private setInitSizes() : void {
      if(!this.imgW || !this.imgH) return;

      const clipper = (this.$refs.imgClipperBasic as Vue);
      if(!clipper) return;
      const clipperEl = clipper.$el;
      const clipperW = clipperEl.clientWidth;
      const clipperH = clipperEl.clientHeight;

      let ratioX = this.imgW / clipperW;
      let ratioY = this.imgH / clipperH;
      if(this.rotation%180 === 90 || this.rotation%180 === -90){
        ratioX = this.imgH / clipperW;
        ratioY = this.imgW / clipperH;
      }

      if(ratioX >= ratioY) {
        this.initWidth = 100;
        this.initHeight = 100 * (ratioY / ratioX);
      }
      else if(ratioY > ratioX) {
        this.initHeight = 100;
        this.initWidth = 100 * (ratioX / ratioY);
      }
    }
  
    /**
     * @desc tourne l'image de l'angle indiqué
     */
    private rotate(angle : number) : void {
      this.rotation += angle;
      this.setInitSizes();
    }

    /**
     * @desc valide et renvoie l'image modifiée, et ferme la boite de dialogue
     */
    private validate() : void{
      setTimeout(async () => {
        this.visible = false;
        this.imgSrc = "";
        const dataUrl = await this.getOutputImg();
        this.$emit('imageCropped', dataUrl, this.returnParam);
      }, 150);
    }

    /**
     * @desc récupère l'image modifiée et la converti en URL
     * @returns l'url base64 de l'image modifiée
     */
    private async getOutputImg() : Promise<string> {
      const canvas = (this.$refs.imgClipper as any || this.$refs.imgClipperBasic).clip({ maxWPixel: 2000 }) as HTMLCanvasElement;
      const type = 'image/jpeg'; // the best practice might be the same type of your uploaded image
      const quality = 1;

      return new Promise<any>((resolve, reject) => {
        canvas.toBlob(async (blob : Blob | null) => {
          if(blob) {
            let maxWidth = imgWidth;
            let maxHeight = imgMaxHeight;
            if(this.largeImage) {
              maxWidth = largeImgMaxWidth;
              maxHeight = largeImgMaxHeight;
            }
            const compressedImg = await this.imgTransferService.resizeImg(blob, maxWidth, maxHeight, 0.9);
            const base64Url = await this.toBase64Data(compressedImg);
            resolve(base64Url);
          }
          else reject();
        }, type, quality);
      });
    }

    /**
     * @desc converti le fichier en base64
     */
    private toBase64Data(blobFile : File) : Promise<any> {
      return new Promise<any>((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result);
        }
        reader.readAsDataURL(blobFile);
      })
    }

    /**
     * @desc annule les modifications
     */
    private cancel() : void {
      setTimeout(() => {
        this.visible = false;
        this.imgSrc = "";
        this.$emit('imageCropped', "");
      }, 200);
    }
  }


